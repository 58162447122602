'use strict'

angular
	.module('cb.presales', [])
	.controller('PresalesController', ["$scope", "$filter", "LeadProfileService", "ReportService", "WebEnquiryService", "NotificationService", function ($scope, $filter, LeadProfileService, ReportService, WebEnquiryService, NotificationService) {
		
		$scope.init = function () {
			$scope.limit = 30;
			$scope.startAtUnprocessedEnquiry = 0;
		};

		$scope.$watch('startAtUnprocessedEnquiry', function () {
			$scope.getUnprocessedEnquiry();
		});

		//Function to handle all unprocessed enquiries
		$scope.getUnprocessedEnquiry = function () {
			$scope.WebEnquiry = {};
			$scope.WebEnquiry.Pagination = {};
			$scope.WebEnquiry.Pagination.StartPage = $scope.startAtUnprocessedEnquiry;
			$scope.WebEnquiry.Pagination.PageSize = $scope.limit;
			$scope.GetWebEnquiriesPromise = WebEnquiryService.getUnprocessedEnquiry($scope.WebEnquiry);
			$scope.GetWebEnquiriesPromise.then(function (output) {
				$scope.webEnquiries = output.data.Results;
				if ($scope.webEnquiries) {
					$scope.webEnquiryHasNextPage = output.data.PaginationStatus.HasPageForwards;
					$scope.webEnquiryHasPreviousPage = output.data.PaginationStatus.HasPageBack;
				}

			}, function (error) {
				$scope.fetchedDataError = true;
				NotificationService.alert('Web Enquiry Error', 'There was an error retriving the unprocessed web enquiries.', 'error', {});
			});
		};

		$scope.decrement = function (pageNumber) {
			$scope[pageNumber]--;
		};

		$scope.increment = function (pageNumber) {
			$scope[pageNumber]++;
		};
	}])

	.controller('PresalesStatusActionController', ["$scope", "$filter", "LeadProfileService", "ReportService", "NotificationService", function ($scope, $filter, LeadProfileService, ReportService, NotificationService) {

		$scope.init = function () {
			$scope.limit = 30;
			$scope.startAtActionPending = 0;
			$scope.salesStatuses = [];
			$scope.statusFilter = null;

			$scope.gettingStatusesPromise = ReportService.GetAllStatuses();
			$scope.gettingStatusesPromise.then((output) => {
				$scope.salesStatuses = output.data.LeadStatus;
			}, (error) => {
				NotificationService.alert('Statuses Error', 'There was an error retriving the list of sales statuses.', 'error', {});
			});
		};

		$scope.$watch('startAtActionPending', function () {
			$scope.getActionPending();
		});

		$scope.$watch('statusFilter', function () {
			$scope.getActionPending();
		});

		$scope.getActionPending = function () {
			$scope.ActionPending = {};
			$scope.ActionPending.Pagination = {};
			$scope.ActionPending.Pagination.StartPage = $scope.startAtActionPending;
			$scope.ActionPending.Pagination.PageSize = $scope.limit;
			$scope.ActionPending.Pagination.Filter = $scope.statusFilter || null;
			$scope.GetActionPendingPromise = LeadProfileService.getPendingLeads($scope.ActionPending);
			$scope.GetActionPendingPromise.then(function (output) {
				$scope.statusActions = output.data.Results;
				$scope.pendingActionPages = output.data.TotalPages;
				if ($scope.statusActions) {
					$scope.actionPendingHasNextPage = output.data.PaginationStatus.HasPageForwards;
					$scope.actionPendingHasPreviousPage = output.data.PaginationStatus.HasPageBack;
				}

			}, function (error) {
				$scope.fetchedDataError = true;
				NotificationService.alert('Action Pending Error', 'There was an error retriving the pending leadprofiles.', 'error', {});
			});	
		};

		$scope.clearFilter = function () {
			$scope.statusFilter = null;
		};

		$scope.decrement = function (pageNumber) {
			$scope[pageNumber]--;
		};

		$scope.increment = function (pageNumber) {
			$scope[pageNumber]++;
		};
	}])

	.controller('PresalesUnquotedController', ["$scope", "$filter", "LeadProfileService", "ReportService", "StaffService", "WebEnquiryService", "NotificationService", function ($scope, $filter, LeadProfileService, ReportService, StaffService, WebEnquiryService, NotificationService) {

		$scope.init = function () {
			$scope.limit = 30;
			$scope.startAtUnquoted = 0;

			//Get Enquiry Handlers. Leads Missing from Unquoted Follow-Up Calls Ticket #22817743
			// Enquiry Handlers are Sales Desk or Marketing roles.
			//$scope.GetAllStaffPromise = StaffService.getAllStaff();
			$scope.GetAllStaffPromise = StaffService.getEnquiryHandlers();
			$scope.GetAllStaffPromise.then(function (output) {
				$scope.allStaffList = output.data.StaffList;
			}, function (error) {
				NotificationService.alert('Staff Error', 'There was an error gathering all the data, please try again.', 'error', {});
			});

		};
		$scope.$watch('startAtUnquoted', function () {
			$scope.getUnquoted();
		});

		$scope.$watch('handlerFilter', function () {
			$scope.getUnquoted();
		});

		$scope.clearFilter = function () {
			$scope.handlerFilter = null;
		};

		$scope.getUnquoted = function () {
			$scope.Unquoted = {};
			$scope.Unquoted.Pagination = {};
			$scope.Unquoted.Pagination.StartPage = $scope.startAtUnquoted;
			$scope.Unquoted.Pagination.PageSize = $scope.limit;

			if ($scope.handlerFilter) {
				
				var data =
				{
					"Pagination": $scope.Unquoted,
					"HandlerId": $scope.handlerFilter
				}

				// New filter. Ticket #22817743.
				$scope.GetUnquotedPromise = LeadProfileService.getUnquotedFollowupLeadFiltered(data);
				$scope.GetUnquotedPromise.then(function (output) {
					$scope.unquotedFollowUpCalls = output.data.Results;
					if ($scope.unquotedFollowUpCalls) {
						$scope.unquotedHasNextPage = output.data.PaginationStatus.HasPageForwards;
						$scope.unquotedHasPreviousPage = output.data.PaginationStatus.HasPageBack;
					}

				}, function (error) {
					$scope.fetchedDataError = true;
					NotificationService.alert('Unquoted Error', 'There was an error retrieving the unquoted leadprofiles filtered.', 'error', {});
				});
			}
			else {
				// No filter.
				$scope.GetUnquotedPromise = LeadProfileService.getUnquotedFollowupLead($scope.Unquoted);
				$scope.GetUnquotedPromise.then(function (output) {
					$scope.unquotedFollowUpCalls = output.data.Results;
					if ($scope.unquotedFollowUpCalls) {
						$scope.unquotedHasNextPage = output.data.PaginationStatus.HasPageForwards;
						$scope.unquotedHasPreviousPage = output.data.PaginationStatus.HasPageBack;
					}

				}, function (error) {
					$scope.fetchedDataError = true;
					NotificationService.alert('Unquoted Error', 'There was an error retrieving the unquoted leadprofiles.', 'error', {});
				});
			}
		};

		$scope.decrement = function (pageNumber) {
			$scope[pageNumber]--;
		};

		$scope.increment = function (pageNumber) {
			$scope[pageNumber]++;
		};
	}])

	.controller('PresalesQuotedController', ["$scope", "$filter", "LeadProfileService", "ReportService", "StaffService", "WebEnquiryService", "NotificationService", function ($scope, $filter, LeadProfileService, ReportService, StaffService, WebEnquiryService, NotificationService) {

		$scope.init = function () {
			$scope.limit = 30;
			$scope.startAtQuoted = 0;

			$scope.GetAllStaffPromise = StaffService.getEnquiryHandlers();
			$scope.GetAllStaffPromise.then(function (output) {
				$scope.allStaffList = output.data.StaffList;
			}, function (error) {
				NotificationService.alert('Staff Error', 'There was an error gathering all the data, please try again.', 'error', {});
			});

		};

		$scope.$watch('startAtQuoted', function () {
			$scope.getQuoted();
		});

		$scope.$watch('handlerFilter', function () {
			$scope.getQuoted();
		});

		$scope.clearFilter = function () {
			$scope.handlerFilter = null;
		};

		$scope.getQuoted = function () {
			$scope.Quoted = {};
			$scope.Quoted.Pagination = {};
			$scope.Quoted.Pagination.StartPage = $scope.startAtQuoted;
			$scope.Quoted.Pagination.PageSize = $scope.limit;

			if ($scope.handlerFilter) {

				var data =
				{
					"Pagination": $scope.Quoted,
					"HandlerId": $scope.handlerFilter
				}


				// New filter. Ticket #22817743.
				$scope.GetQuotedPromise = LeadProfileService.getQuotedFollowupLeadFiltered(data);
				$scope.GetQuotedPromise.then(function (output) {
					$scope.quotedFollowUpCalls = output.data.Results;
					if ($scope.quotedFollowUpCalls) {
						$scope.quotedHasNextPage = output.data.PaginationStatus.HasPageForwards;
						$scope.quotedHasPreviousPage = output.data.PaginationStatus.HasPageBack;
					}

				}, function (error) {
					$scope.fetchedDataError = true;
					NotificationService.alert('Quoted Error', 'There was an error retrieving the quoted leadprofiles filtered.', 'error', {});
				});

			}
			else {
				// No filter.
				$scope.GetQuotedPromise = LeadProfileService.getQuotedFollowupLead($scope.Quoted);
				$scope.GetQuotedPromise.then(function (output) {
					$scope.quotedFollowUpCalls = output.data.Results;
					if ($scope.quotedFollowUpCalls) {
						$scope.quotedHasNextPage = output.data.PaginationStatus.HasPageForwards;
						$scope.quotedHasPreviousPage = output.data.PaginationStatus.HasPageBack;
					}
				}, function (error) {
					$scope.fetchedDataError = true;
					NotificationService.alert('Quoted Error', 'There was an error retrieving the quoted leadprofiles.', 'error', {});
				});
			}

		};

		$scope.decrement = function (pageNumber) {
			$scope[pageNumber]--;
		};

		$scope.increment = function (pageNumber) {
			$scope[pageNumber]++;
		};
	}])


